
import { componentSize } from "element-plus/es/utils/props";
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    article: {},
  },
  methods: {
    gotoSearch(type: string, data: any) {
      // console.log('点击标签')
      this.$router.push({
        name: "search",
        query: {
          [type]: data,
        },
      });
    },
  },
})
export default class ArticleCard extends Vue {}
