
import {
  get_all_article_list,
  get_all_field_list,
  get_article_list,
  get_field_list,
  get_tag_list,
} from "@/api/article.api";
import { onMounted, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapState } from "vuex";
import ArticleCard from "@/components/ArticleCard.vue";

@Options({
  components: { ArticleCard },
  async created() {
    document.title = "文章搜索 | leoyi编程日志";
    // 获取分区列表和标签列表
    this.field_name_list = await this.request_field_list();
    this.tag_name_list = await get_tag_list();
    if (this.urlQuery.search_all) {
      this.searchWhenCreated = true;
    }
    // 查看是否url里带有fields或者tags的参数
    if (this.urlQuery.field) {
      const index = this.field_name_list.findIndex((item: any) => {
        return item.field == this.urlQuery.field;
      });
      this.field = index > -1 ? this.field_name_list[index]["id"] : null;
      this.searchWhenCreated = true;
    }
    if (this.urlQuery.tag) {
      this.tags.push(this.urlQuery.tag);
      this.searchWhenCreated = true;
    }
    if (this.searchWhenCreated) {
      await this.search();
    }
  },
  data() {
    return {
      searchWhenCreated: false,
      keyword: null,
      field: null,
      field_name_list: [],
      isVisiable: false,
      tag_name_list: [],
      tags: ref<string[]>([]),
      args: {} as {
        keyword?: string;
        fieldId?: number;
        tags?: string[];
      },
      article_list: null,
      // 文章列表分页设置
      article_total: 0, // 文章总量
      article_page: 1, // 当前页面
      article_page_size: 5, // 页面大小
    };
  },
  computed: {
    ...mapState("user", ["isLogin"]),
    search_article_list() {
      if (this.isLogin) {
        return get_all_article_list;
      } else {
        return get_article_list;
      }
    },
    request_field_list() {
      if (this.isLogin) {
        return get_all_field_list;
      } else {
        return get_field_list;
      }
    },
    urlQuery() {
      return this.$route.query;
    },
  },
  watch: {
    async article_page(newPage, curPage) {
      // 请求文章列表
      const res = await this.search_article_list({
        userId: 1,
        ...this.args,
        offset: (this.article_page - 1) * this.article_page_size,
        num: this.article_page_size,
      });
      this.article_list = res.list;
      this.article_total = res.total;
    },
    async urlQuery() {
      if (this.$route.name == "search")
        window.open(window.location.href, "_blank");
    },
  },
  methods: {
    // 搜索文章列表
    async search() {
      this.article_list = null;
      this.article_total = 0;
      this.args = {};
      if (this.keyword) {
        this.args.keyword = this.keyword;
      }
      if (this.field) {
        this.args.fieldId = this.field;
      }
      if (this.tags.length > 0) {
        this.args.tags = this.tags;
      }
      const res = await this.search_article_list({
        userId: 1,
        num: this.article_page_size,
        offset: 0,
        ...this.args,
      });
      this.article_list = res.list;
      this.article_total = res.total;
    },
  },
})
export default class Search extends Vue {}
